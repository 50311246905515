<template>
  <div class="feat-car-placeholder">
    <ul class="card">
      <li v-for="(item, index) in 1" :key="index" class="feat-slide loading addBorder">
      </li>
    </ul>
    <!-- <div class="mobile-card"></div> -->
    <div class="feat-nav-container" id="feat-nav-container">
      <button class="nav-button" type="button" v-for="(dummyImage, key) in 4" :key="key"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselPlaceholder",
  data() {
    return {
      addRightBorder: "add-right-border",
      addBorder: "addBorder",
      addLeftBorder: "add-left-border"
    };
  }
};
</script>

<style lang="scss">
@import "@/sass/_components.scss";
@import "./FeaturedCarouselPlaceholder.scss"
</style>
